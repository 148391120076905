<template>
  <b-row class="mt-2">
    <b-col
      xs="2"
      md="2"
      lg="2"
    />
    <b-col
      v-for="item in etats"
      :key="item.icon"
      xs="2"
      md="2"
      lg="2"
      class="centerdCard"
    >
      <b-avatar
        class="mb-1"
        :variant="statusOk(item.value)"
        size="45"
      >
        <feather-icon
          size="20"
          :icon="item.icon"
        />
      </b-avatar>
      <div class="truncate">
        <h6 class="mb-25 font-weight-bolder">
          {{ $t(item.label) }}
        </h6>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BAvatar,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BAvatar,
  },
  props: {
    etats: {
      type: Array,
      default: () => [],
    },
    active: {
      type: String,
      default: () => 'free',
    },
  },
  methods: {
    statusOk(value) {
      // eslint-disable-next-line default-case
      switch (this.active) {
        case 'free':
          if (['free'].includes(value)) return 'light-success'
          break
        case 'fixing':
          if (['free', 'fixing'].includes(value)) return 'light-success'
          break
        case 'wait':
          if (['free', 'fixing', 'wait'].includes(value)) return 'light-success'
          break
        case 'done':
        case 'payee':
          if (['free', 'fixing', 'wait', 'done', 'payee'].includes(value)) return 'light-success'
          break
        case 'annulee':
          if (['annulee'].includes(value)) return 'light-success'
          break
      }
      return 'light-secondary'
    },
  },

}
</script>

<style scoped>
.centerdCard{
  text-align: center;
}
</style>
