<template>
  <div>
    <b-card>
      <b-row>
        <b-col
          order-xs="2"
          order-md="1"
          cols="12"
          md="8"
        >
          <b-badge
            v-if="hotline.etat"
            :variant="variantEtat(hotline.etat)"
            class="mb-1"
          >
            {{ $t(`REQUEST.ETAT.${hotline.etat}`) }}
          </b-badge>
          <h5>
            <span class="numTicket">#{{ hotline.idHotline }}</span> -  {{ hotline.hotline }}
          </h5>
        </b-col>
        <b-col
          order-xs="1"
          order-md="2"
          cols="12"
          md="4"
          class="text-right"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mr-0"
            @click="$router.go(-1)"
          >
            <feather-icon
              icon="RewindIcon"
            />
            {{ $t('BUTTON.BACK') }}
          </b-button>

        </b-col>
      </b-row>

      <hr class="d-xs-none d-sm-none d-md-flex">

      <timeline-etat
        class="d-xs-none d-sm-none d-md-flex"
        :etats="etats"
        :active="hotline.etat"
      />

      <hr>

      <b-row>
        <b-col
          cols="6"
          md="3"
        >
          <dl v-if="hotline.idContact != null">
            <dt>{{ $t('REQUEST.CREATEUR') }} :</dt>
            <dd>{{ hotline.idContact.prenom != null ? hotline.idContact.prenom : '' }} {{ hotline.idContact.nom }} <br> {{ hotline.idSociete.nom_commercial || hotline.idSociete.societe }}</dd>
          </dl>
        </b-col>
        <b-col
          cols="6"
          md="3"
        >
          <dl>
            <dt>{{ $t('REQUEST.DATE') }} :</dt>
            <dd>{{ hotline.date | luxon("dd/MM/yyyy HH:mm") }}</dd>
          </dl>
        </b-col>

        <b-col
          cols="4"
          md="2"
        >
          <dl v-if="hotline.urgence">
            <dt>{{ $t('REQUEST.URGENCE') }} :</dt>
            <dd>
              <b-badge
                :variant="getUrgence()"
                class="mb-1"
              >
                <span>{{ $t(`REQUEST.URGENCES.${hotline.urgence}`) }}</span>

              </b-badge>

            </dd>
          </dl>
        </b-col>

        <b-col
          cols="8"
          md="4"
        >
          <dl>
            <dt>{{ $t('REQUEST.FACTURATION.LABEL') }} :</dt>
            <dd>
              <b-badge
                :variant="getFacturation('variant')"
                class="mb-1"
              >
                <span v-if="getFacturation('label') === 'CHARGE_CODENAME'">
                  {{ $t(`REQUEST.FACTURATION.${getFacturation('label')}`) }} {{ $t(`CODENAME.${codename}`) }}
                </span>
                <span v-else>{{ $t(`REQUEST.FACTURATION.${getFacturation('label')}`) }}</span>

                <span v-if="getFacturation('displayCredit')"> - {{ $t('REQUEST.CREDIT_CONSOMME') }} : {{ creditConsomme }}</span>
              </b-badge>
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <b-card>
          <dl>
            <dt>{{ $t('REQUEST.DETAIL') }} :</dt>
            <dd><p v-html="hotline.detail" /></dd>
          </dl>

          <dl v-if="hotline.pj == '1'">
            <dd>
              <b-button
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="flat-primary"
                @click="downloadHotlinePJ()"
              >
                <b-badge variant="danger">
                  ZIP
                </b-badge>
                {{ hotline.hotline }}.zip
                <feather-icon
                  icon="DownloadIcon"
                  class="ml-50 mb-25"
                />
              </b-button>
            </dd>
          </dl>

          <dl v-if="canInsertInteraction(hotline.etat) === 'oui'">
            <b-form @submit.prevent>
              <dt>
                <hr>
                {{ $t('REQUEST.NOUVELLE_INTERACTION') }} :
              </dt>
              <dd>
                <quill-editor
                  v-model="newInteraction"
                  :options="editorOption"
                  class="mb-2"
                />

                <vue-dropzone
                  id="fileUpload"
                  ref="dropzone"
                  :options="dropOptions"
                  :use-custom-slot="true"
                  @vdropzone-file-added="afterComplete"
                  @vdropzone-removed-file="removeFile"
                  @vdropzone-max-files-exceeded="removeFileFromDropZone"
                  @vdropzone-error="checkiFErrorDropzone"
                >
                  <div class="dropzone-custom-content">
                    <h3 class="dropzone-custom-title">
                      {{ $t('DRAG_DROP_PLACEHOLDER') }}
                    </h3><br>
                    <span>{{ $t('REQUEST.DRAG_DROP_AUTHORIZED_FILES') }}</span>
                  </div>

                </vue-dropzone>

                <b-button
                  v-if="!isErrorDropzone"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon ml-0 mt-50"
                  @click="sendInteraction()"
                >
                  {{ $t('REQUEST.BUTTON_SUBMIT') }}
                </b-button>
              </dd>
            </b-form>
          </dl>

          <dl v-if="hotline.facturationTicket === 'oui' && hotline.okFacturation == null">
            <dd>
              <b-alert
                variant="danger"
                show
              >
                <div class="alert-body">
                  <span>
                    {{ $t('REQUEST.ALERT_FACTURATION') }}. <span @click="showAlertFacturation"><u>{{ $t('REQUEST.ALERT_FACTURATION_TOOLTIP') }}</u></span>
                  </span>
                </div>
              </b-alert>

              <quill-editor
                v-model="detailFacturation"
                :options="editorOptionFacturation"
                class="mb-2"
              />
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="btn-icon mr-2"
                  @click="acceptFacturation('oui')"
                >
                  {{ $t('REQUEST.ACCEPT_FACTURATION') }}
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon ml-2"
                  @click="acceptFacturation('non')"
                >
                  {{ $t('REQUEST.REFUS_FACTURATION') }}
                </b-button>
              </div>
            </dd>
          </dl>

          <dl v-if="hotline.etat === 'done' && hotline.indiceSatisfaction == null">
            <b-alert
              variant="danger"
              show
            >
              <div class="alert-body">
                <span>
                  {{ $t('REQUEST.ALERT_INDICE_SATISFACTION') }}. <span @click="showAlertSatisfaction"><u>{{ $t('REQUEST.ALERT_SATISFACTION_TOOLTIP') }}</u></span>
                </span>
              </div>
            </b-alert>
            <dt>
              <div class="mb-2">
                <label class="font-weight-bolder"> {{ $t('REQUEST.SATISFACTION_LABEL') }}:</label>

                <b-form-rating
                  v-model="indiceSatisfaction"
                  no-border
                  stars="5"
                  inline
                  variant="primary"
                />
              </div>
            </dt>
            <dd>

              <quill-editor
                v-model="detailSatisfaction"
                :options="editorOption"
                class="mb-2"
              />

              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-center">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="success"
                  class="btn-icon mr-2"
                  @click="acceptResolution('oui')"
                >
                  {{ $t('REQUEST.ACCEPT_SATISFACTION') }}
                </b-button>

                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="warning"
                  class="btn-icon ml-2"
                  @click="acceptResolution('non')"
                >
                  {{ $t('REQUEST.REFUS_SATISFACTION') }}
                </b-button>
              </div>
            </dd>
          </dl>

          <dl v-if="hotline.etat === 'done' && hotline.indiceSatisfaction !== null">
            <dt>{{ $t('REQUEST.SATISFACTION_LABEL') }}</dt>
            <dd>
              <b-form-rating
                v-model="hotline.indiceSatisfaction"
                no-border
                stars="5"
                inline
                variant="primary"
                readonly
              />

            </dd>

          </dl>

        </b-card>
      </b-col>

      <b-col
        cols="12"
        md="8"
      >
        <b-card>
          <timeline-interactions />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BBadge, BButton, BAlert, BFormRating, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import timelineEtat from '@/views/requests/details/TimelineEtat.vue'
import timelineInteractions from '@/views/requests/details/TimelineInteractions.vue'

// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'

import vueDropzone from 'vue2-dropzone'

export default {
  components: {
    BCard,
    BBadge,
    BRow,
    BCol,
    BButton,
    BAlert,
    BFormRating,
    BForm,

    quillEditor,

    timelineEtat,
    timelineInteractions,

    vueDropzone,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      loading: false,
      etats: [
        { value: 'free', label: 'REQUEST.ETAT.free', icon: 'LayersIcon' },
        { value: 'fixing', label: 'REQUEST.ETAT.fixing', icon: 'LayersIconPlus' },
        { value: 'wait', label: 'REQUEST.ETAT.wait', icon: 'InboxIcon' },
        { value: 'done', label: 'REQUEST.ETAT.done', icon: 'ThumbsUpIcon' },
        { value: 'annulee', label: 'REQUEST.ETAT.annulee', icon: 'TrashIcon' },
      ],
      newInteraction: '',
      editorOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
          ],
        },
        placeholder: this.$i18n.t('REQUEST.EDITOR_INTERACTION_PLACEHOLDER'),
      },
      editorOptionFacturation: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            [{ indent: '-1' }, { indent: '+1' }],
            [{ color: [] }, { background: [] }],
          ],
        },
        placeholder: this.$i18n.t('REQUEST.EDITOR_FACTURATION_PLACEHOLDER'),
      },
      isErrorDropzone: false,
      formFiles: [],
      files: [],

      detailFacturation: '',
      detailSatisfaction: '',
      indiceSatisfaction: 0,

      dropOptions: {
        autoProcessQueue: false,
        url: 'http://localhost/',
        maxFilesize: 2,
        maxFiles: 4,
        chunking: true,
        chunkSize: 500,
        thumbnailWidth: 200,
        thumbnailHeight: 200,
        addRemoveLinks: true,
        dictRemoveFile: 'Retirer',
        acceptedFiles: 'image/*,.xlsx,.xls,.pdf,.doc,.docx, .zip',
      },
    }
  },
  computed: {
    hotline() {
      return this.$store.getters['hotline/getHotline']
    },
    interactions() {
      return this.$store.getters['hotline/getInteractions']
    },
    creditConsomme() {
      const nbrCreditConsomme = this.$store.getters['hotline/getCreditConsomme']
      const strCreditConsomme = nbrCreditConsomme.toString().replace('.', ',')
      return strCreditConsomme
    },
    codename() {
      return localStorage.getItem('codename')
    },
    activeSociete() {
      return this.$store.getters['societe/getActiveSociete']
    },
  },
  mounted() {
    this.$store.dispatch('hotline/getNotificationsHotline', { idSociete: this.activeSociete.idSociete })
    if (this.$route.params.id && Number.isInteger(parseInt(this.$route.params.id, 10))) {
      this.hotline.idHotline = this.$route.params.id
      this.$store.dispatch('hotline/fetchOne', this.hotline.idHotline)
        .then(() => {
          if (this.hotline.etat === 'annulee') {
            this.etats = [
              { value: 'free', label: 'REQUEST.ETAT.free', icon: 'LayersIcon' },
              { value: 'fixing', label: 'REQUEST.ETAT.fixing', icon: 'LayersIconPlus' },
              { value: 'wait', label: 'REQUEST.ETAT.wait', icon: 'InboxIcon' },
              { value: 'annulee', label: 'REQUEST.ETAT.annulee', icon: 'TrashIcon' },
            ]
          } else {
            this.etats = [
              { value: 'free', label: 'REQUEST.ETAT.free', icon: 'LayersIcon' },
              { value: 'fixing', label: 'REQUEST.ETAT.fixing', icon: 'LayersIconPlus' },
              { value: 'wait', label: 'REQUEST.ETAT.wait', icon: 'InboxIcon' },
              { value: 'done', label: 'REQUEST.ETAT.done', icon: 'ThumbsUpIcon' },
            ]
          }
        }).catch(e => {
          this.$router.push('/pages/error-404')
        })
    } else {
      this.$router.push('/pages/error-404')
    }
  },
  methods: {
    variantEtat(etat) {
      switch (etat) {
        case 'free':
          return 'danger'
        case 'fixing':
          return 'success'
        case 'wait':
          return 'warning'
        case 'done':
        case 'payee':
          return 'primary'
        case 'annulee':
          return 'secondary'

        default:
          return 'primary'
      }
    },
    getFacturation(retour) {
      let variant = 'info'
      let label = 'CHARGE_NON_DEFINI'
      let displayCredit = false

      if (this.hotline.facturationTicket === 'oui') {
        if (this.hotline.idAffaire) {
          variant = 'success'
          label = 'SUR_AFFAIRE'
          displayCredit = false
        } else {
          variant = 'warning'
          label = 'CHARGE_CLIENT'
          displayCredit = true
        }
      } else if (this.hotline.facturationTicket === 'non') {
        variant = 'primary'
        label = 'CHARGE_CODENAME'
        displayCredit = false
      }

      // eslint-disable-next-line default-case
      switch (retour) {
        case 'label': return label
        case 'variant': return variant
        case 'displayCredit': return displayCredit
      }
      return null
    },
    getUrgence() {
      // eslint-disable-next-line default-case
      switch (this.hotline.urgence) {
        case 'detail':
          return 'info'

        case 'genant':
          return 'warning'

        case 'bloquant':
          return 'danger'
      }
      return 'info'
    },
    canInsertInteraction(etat) {
      // Facturation au ticket mais facturation non acceptée par le client
      if (this.hotline.facturationTicket === 'oui' && this.hotline.okFacturation === null) return 'non'

      // Ticket non annulé ou cloturé
      if (!['done', 'payee', 'annulee', ''].includes(etat)) return 'oui'

      return 'non'
    },
    sendInteraction() {
      const that = this
      const form = new FormData()

      if (that.formFiles.length) {
        for (let i = 0; i < that.formFiles.length; i += 1) {
          form.append(`files[${i}]`, that.formFiles[i])
        }
      }
      form.append('idHotline', that.hotline.idHotline)
      form.append('interaction', that.newInteraction)

      that.$store.dispatch('hotline/newInteraction', form)
        .then(() => {
          that.loading = false
          this.$store.dispatch('hotline/getNotificationsHotline', { idSociete: this.activeSociete.idSociete })
          that.success()
        })
        .catch(() => {
          that.$swal(
            {
              title: `${this.$i18n.t('REQUEST.ERROR_MODAL_TITRE')}`,
              html: `${this.$i18n.t('REQUEST.ERROR_MODAL_TEXT')}`,
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            },
          )
          that.errorShow = true
          that.loading = false
        })
    },
    /**
     * Methode qui d'afficher la modal de succes de création d'une demande
     * Update les notifications dans la nabbar
     * Reinitialise le formulaire
     * Renvoi vers la pages des requetes qu user
     * @author Morgan Fleurquin <mfleurquin@absystech.fr>
     */
    success() {
      // On affiche la popup de succes
      this.$swal(
        {
          title: `${this.$i18n.t('REQUEST.SUCCESS_MODAL_INTERACTION_TITRE')}`,
          html: `${this.$i18n.t('REQUEST.SUCCESS_MODAL_INTERACTION_TEXT')}`,
          icon: 'success',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        },
      ).then(isConfirm => {
        if (isConfirm) {
          // On reinitialise le formulaire
          this.newInteraction = ''
          this.formFiles = []
          this.files = []
          this.$refs.dropzone.removeAllFiles()
        }
      })
    },
    showAlertFacturation() {
      this.$swal({
        title: `${this.$i18n.t('REQUEST.MODAL_FACTURATION_TITLE')}`,
        html: `<div class="text-left">${this.$i18n.t('REQUEST.MODAL_FACTURATION_TEXT')}</div>`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    showAlertSatisfaction() {
      this.$swal({
        title: `${this.$i18n.t('REQUEST.MODAL_SATISFACTION_TITLE')}`,
        html: `<div class="text-left">${this.$i18n.t('REQUEST.MODAL_SATISFACTION_TEXT')}</div>`,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    acceptFacturation(response) {
      this.$store.dispatch('hotline/acceptRefusFacturation', { idHotline: this.hotline.idHotline, okFacturation: response, detail: this.detailFacturation })
    },
    acceptResolution(response) {
      this.$store.dispatch('hotline/acceptRefusResolution', {
        idHotline: this.hotline.idHotline, validationCloture: response, indiceSatisfaction: this.indiceSatisfaction.toString(), detail: this.detailSatisfaction,
      })
    },
    downloadHotlinePJ() {
      this.$store.dispatch('hotline/downloadHotlinePJ', this.hotline.idHotline)
        .then(data => {
          const linkSource = `data:${data.contentType};base64,${data.base64}`
          const downloadLink = document.createElement('a')
          downloadLink.href = linkSource
          downloadLink.download = data.name
          downloadLink.click()
        })
    },
    checkiFErrorDropzone(file) {
      if (file) {
        this.isErrorDropzone = true
      } else if (this.$refs.dropzone && this.$refs.dropzone.getRejectedFiles().length > 0) {
        this.isErrorDropzone = true
      } else {
        this.isErrorDropzone = false
      }
    },
    /**
     * Surcharge du after complete de la dropzone
     * @author Morgan Fleurquin <mfleurquin@absystech.fr>
     */
    afterComplete(file) {
      this.formFiles.push(file)
      return true
    },
    /**
     * Surcharge du remove file de la dropzone
     * @author Morgan Fleurquin <mfleurquin@absystech.fr>
     */
    removeFile(file) {
      this.formFiles = this.formFiles.filter(f => f !== file)
      this.checkiFErrorDropzone()
    },
    /**
     * Méthode permettant de supprimer le dernier fichier ajouté
     * si la limite de de fichiers acceptés est dépassée
     * @author Francisco Fernandez
     */
    removeFileFromDropZone(file) {
      this.$refs.dropzone.removeFile(file)
      this.checkiFErrorDropzone()
    },
  },
}
</script>
<style>
.numTicket{
  font-weight: 600;
}

.quill-editor{
  margin-top: 20px;
}
.ql-container {
  min-height: 250px;
}

body.dark-layout .ql-stroke{
  stroke: #b4b7bd ;
}
body.dark-layout  .ql-editor.ql-blank::before {
  color: #b4b7bd ;
}

#fileUpload{
  margin-bottom: 10px;
  background: none;;
}
</style>
