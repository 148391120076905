<template>
  <app-timeline>
    <div v-if="interactions.length > 0">
      <div
        v-for="interaction in interactions"
        :key="interaction.idInteraction"
      >
        <app-timeline-item
          :icon="iconinteraction()"
          :variant="variantinteraction(interaction, false)"
          class="mb-2"
        >
          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
            <h6>{{ interactionUtilisateur(interaction) }}</h6> <small class="text-right font-weight-bolder">{{ interaction.date | luxon({ output: "dd/MM/y HH:mm" }) }}</small>
            <b-badge
              v-if="interaction.visible == 'non'"
              pill
              variant="light-warning"
            >
              {{ $t('REQUEST.INTERACTION.INTERNEinteraction') }}
            </b-badge>
          </div>
          <p v-html="interaction.detail" />

          <div class="text-right">
            <b-button
              v-if="interaction.pj != '0'"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              class="btn-icon"
              size="sm"
              @click="downloadPJ(interaction.pj)"
            >
              <feather-icon
                icon="DownloadIcon"
              />
            </b-button>
          </div>

          <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between">
            <small>&nbsp;</small>
            <small
              v-if="interaction.utilisateur !== null && interaction.credit !== 0"
              class="text-muted text-right"
            >
              {{ $t('REQUEST.INTERACTION.CREDIT_CONSOMME') }} : {{ interaction.credit }}
            </small>
          </div>

          <hr>
        </app-timeline-item>
      </div>
    </div>
    <div v-else>
      <h5> {{ $t('REQUEST.AUCUNE_INTERACTION') }}</h5>
    </div>
  </app-timeline>
</template>

<script>

import { BBadge, BButton } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BBadge,
    BButton,

    AppTimeline,
    AppTimelineItem,
  },
  directives: {
    Ripple,
  },
  computed: {
    interactions() {
      const interactions = this.$store.getters['hotline/getInteractions']
      interactions.forEach(element => {
        // eslint-disable-next-line no-param-reassign
        element.credit = element.credit.toString().replace('.', ',')
      })
      return interactions
    },
  },
  methods: {
    iconinteraction() {
      return 'UserIcon'
    },
    variantinteraction(interaction, light) {
      let variant = ''
      if (light) variant = 'light-'

      if (interaction.utilisateur != null) return `${variant}primary`
      if (interaction.contact != null) return `${variant}info`
      return variant
    },
    interactionUtilisateur(interaction) {
      if (interaction.utilisateur != null) {
        return `${interaction.utilisateur.prenom} ${interaction.utilisateur.nom}`
      }

      if (interaction.contact != null) {
        if (interaction.contact.prenom != null) {
          return `${interaction.contact.prenom} ${interaction.contact.nom}`
        }
        return `${interaction.contact.nom}`
      }
      return ''
    },
    downloadPJ(data) {
      const linkSource = `data:${data.contentType};base64,${data.base64}`
      const downloadLink = document.createElement('a')
      downloadLink.href = linkSource
      downloadLink.download = data.name
      downloadLink.click()
    },
  },
}
</script>
